import { useQuery } from '@tanstack/react-query';
import { getAppVersion, getRPDXVersion, getRapidPBRVersion } from '../actions/versions';

export const versionsQueryKey = ['versions'];

export function useVersionsQuery() {
  return useQuery({
    queryKey: versionsQueryKey,
    queryFn: async () => {
      const rpdxVersions = await getRPDXVersion();
      const pbrVersion = await getRapidPBRVersion();
      const appVersion = await getAppVersion()

      return { pbrVersion, rpdxVersions, appVersion };
    },
  });
}

'use client';

import {
  createContext,
  PropsWithChildren,
  SyntheticEvent,
  useContext,
  useState,
} from 'react';
import { SupportTicketModal } from '../components/supportTicket/SupportTicketModal';
import { ModelDescriptor } from '../components/supportTicket/types';

type CreateSupportTicketContextType = {
  open: (model?: ModelDescriptor) => void;
  close: () => void;
};

const CreateSupportTicketContext =
  createContext<CreateSupportTicketContextType>({
    open: () => {},
    close: () => {},
  });

export const CreateSupportTicketProvider = ({
  children,
}: PropsWithChildren) => {
  const [opened, setOpened] = useState(false);
  const [model, setModel] = useState<ModelDescriptor | undefined>();

  const open = (model?: ModelDescriptor) => {
    if (model) {
      setModel(model as ModelDescriptor);
    }
    setOpened(true);
  };
  const close = () => {
    setOpened(false);
    setModel(undefined);
  };

  return (
    <CreateSupportTicketContext.Provider value={{ open, close }}>
      {children}
      <SupportTicketModal opened={opened} onClose={close} initModel={model} />
    </CreateSupportTicketContext.Provider>
  );
};

export const useCreateSupportTicket = () => {
  const context = useContext(CreateSupportTicketContext);

  if (!context) {
    throw new Error(
      'useCreateSupportTicket must be used within CreateSupportTicketProvider'
    );
  }

  return context;
};

import {
  Box,
  Divider,
  Modal,
  ModalProps,
  ScrollArea,
  Text,
} from '@mantine/core';
import { ForwardedRef, PropsWithChildren, ReactNode } from 'react';

interface ScrollableModalProps extends PropsWithChildren, ModalProps {
  opened: boolean;
  title: string;
  nonScrollableTop?: ReactNode;
  nonScrollableBottom?: ReactNode;
  viewportRef?: ForwardedRef<HTMLDivElement>;
}
export function ScrollableModal({
  opened,
  title,
  nonScrollableTop,
  viewportRef,
  children,
  nonScrollableBottom,
  ...props
}: ScrollableModalProps) {
  return (
    <Modal
      opened={opened}
      classNames={{
        root: 'h-full',
        content: 'flex flex-col',
        body: 'flex flex-col h-full  pb-0',
        header: 'py-0',
      }}
      title={
        <Text size='lg' fw={600}>
          {title}
        </Text>
      }
      {...props}
    >
      <Divider mx={-24} />
      {nonScrollableTop}
      <ScrollArea.Autosize
        mah={800}
        offsetScrollbars={true}
        viewportRef={viewportRef}
      >
        {children}
      </ScrollArea.Autosize>
      <Box>
        <Divider mx={-24} />
        {nonScrollableBottom}
      </Box>
    </Modal>
  );
}

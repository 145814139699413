import Link from 'next/link';
import ExternalLinkIcon from '@/icons/external-link.svg';
import { PropsWithChildren } from 'react';
import { Group } from '@mantine/core';

export function ExternalLink({
  href,
  target,
  children,
}: PropsWithChildren & { href: string; target?: string }) {
  return (
    <Link target={target} href={href}>
      <Group gap={0}>
        {children}
        <ExternalLinkIcon style={{ height: '1em', color: 'transparent' }} />
      </Group>
    </Link>
  );
}
